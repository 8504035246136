var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"usermanagement-form",attrs:{"id":"UserManagementForm"}},[_c('app-title-content',{staticClass:"mb-4",attrs:{"items":_vm.mode === 'add' ? _vm.breadcrumbs : _vm.breadcrumbsEdit}}),_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('div',{staticClass:"headline-list align-items-center d-flex mb-4"},[_vm._v(" ส่วนที่ 1 : "),_c('span',{staticClass:"weight-700 ml-2"},[_vm._v(_vm._s(_vm.titleName))])]),_c('div',{staticClass:"blue-box"},[_c('div',{staticClass:"header"},[_vm._v("ข้อมูลผู้ใช้งาน")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 label-min-width"},[_vm._v(" Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" : ")]),_c('div',{staticClass:"flex-fill"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username),expression:"form.username"}],staticClass:"form-control input-custom",class:{
                    'input--error':
                      _vm.submit && _vm.$v.form.username.$invalid && _vm.mode === 'add',
                  },attrs:{"type":"text","disabled":_vm.mode === 'edit'},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "username", $event.target.value)}}})])])]),_c('div',{staticClass:"col-12 col-lg-6 align-self-center"},[(
                _vm.submit &&
                !(_vm.$v.form.username.required || _vm.$v.form.username.canUse) &&
                !_vm.$v.form.username.$pending &&
                _vm.mode === 'add'
              )?_c('span',{staticClass:"text--error"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times-circle']}}),_vm._v(" Username นี้ถูกใช้งานแล้ว ")],1):_vm._e(),(
                _vm.submit &&
                _vm.$v.form.username.required &&
                _vm.$v.form.username.canUse &&
                !_vm.$v.form.username.$pending &&
                _vm.mode === 'add'
              )?_c('span',{staticClass:"text-success"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'check-circle']}}),_vm._v(" สามารถใช้งาน Username นี้ได้ ")],1):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 label-min-width"},[_vm._v(" ชื่อ "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" : ")]),_c('div',{staticClass:"flex-fill"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstname),expression:"form.firstname"}],staticClass:"form-control input-custom",class:{
                    'input--error': _vm.submit && _vm.$v.form.firstname.$invalid,
                  },attrs:{"type":"text"},domProps:{"value":(_vm.form.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstname", $event.target.value)}}})])])]),_c('div',{staticClass:"col-12 col-lg-6"})]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 label-min-width"},[_vm._v(" นามสกุล "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" : ")]),_c('div',{staticClass:"flex-fill"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastname),expression:"form.lastname"}],staticClass:"form-control input-custom",class:{
                    'input--error': _vm.submit && _vm.$v.form.lastname.$invalid,
                  },attrs:{"type":"text"},domProps:{"value":(_vm.form.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastname", $event.target.value)}}})])])]),_c('div',{staticClass:"col-12 col-lg-6"})]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 label-min-width"},[_vm._v(" อีเมล "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" : ")]),_c('div',{staticClass:"flex-fill"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control input-custom",class:{
                    'input--error':
                      _vm.submit &&
                      _vm.$v.form.email.$invalid &&
                      !_vm.$v.form.email.$pending,
                  },attrs:{"type":"text"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})])])]),_c('div',{staticClass:"col-12 col-lg-6 align-self-center"},[(
                _vm.submit && !_vm.$v.form.email.canUse && !_vm.$v.form.email.$pending
              )?_c('span',{staticClass:"text--error"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times-circle']}}),_vm._v(" อีเมลนี้ถูกใช้งานแล้ว ")],1):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 label-min-width"},[_vm._v("เบอร์โทรติดต่อ :")]),_c('div',{staticClass:"flex-fill"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phoneNumber),expression:"form.phoneNumber"}],staticClass:"form-control input-custom",attrs:{"type":"text"},domProps:{"value":(_vm.form.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phoneNumber", $event.target.value)}}})])])]),_c('div',{staticClass:"col-12 col-lg-6"})]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 label-min-width"},[_vm._v(" สังกัดในกรมทรัพยากรน้ำ "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" : ")]),_c('div',{staticClass:"flex-fill"},[_c('select2',{staticClass:"select-custom",class:{
                    'input--error':
                      _vm.submit && _vm.$v.form.departmentSelected.$invalid,
                  },attrs:{"options":_vm.departmentList,"settings":{
                    language: {
                      noResults: function () {
                        return 'ไม่พบข้อมูล';
                      },
                    },
                  }},model:{value:(_vm.form.departmentSelected),callback:function ($$v) {_vm.$set(_vm.form, "departmentSelected", $$v)},expression:"form.departmentSelected"}})],1)])]),_c('div',{staticClass:"col-12 col-lg-6"})])])]),_c('div',{staticClass:"headline-list align-items-center d-flex mb-4 mt-4"},[_vm._v(" ส่วนที่ 2 : "),_c('span',{staticClass:"weight-700 ml-2"},[_vm._v("ตั้งค่ารหัสผ่าน")])]),_c('div',{staticClass:"blue-box"},[_c('div',{staticClass:"header"},[_vm._v("ตั้งค่ารหัสผ่าน")]),_c('div',{staticClass:"content"},[(_vm.mode === 'add')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"d-flex align-items-baseline mb-3"},[_c('div',{staticClass:"mr-2"},[_vm._v(" รหัสผ่าน "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" : ")]),_c('div',{staticClass:"flex-fill"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control input-custom",class:{
                        'input--error': _vm.submit && _vm.$v.form.password.$invalid,
                      },attrs:{"type":"text"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}})]),_c('div',[_c('small',{staticClass:"font-navyblue",class:[
                        {
                          'text--error': _vm.submit && _vm.$v.form.password.$invalid,
                        } ]},[_vm._v(" *รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร ประกอบด้วย a-z, A-Z, 0-9 และอักขระพิเศษ ")])])])])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('div',{staticClass:"mr-2"},[_vm._v(" ยืนยันรหัสผ่าน "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" : ")]),_c('div',{staticClass:"flex-fill"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirmpassword),expression:"form.confirmpassword"}],staticClass:"form-control input-custom",class:{
                      'input--error':
                        _vm.submit && _vm.$v.form.confirmpassword.$invalid,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.form.confirmpassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirmpassword", $event.target.value)}}})])])])])]:[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-search btn-width",on:{"click":_vm.changePassword}},[_vm._v(" ตั้งค่ารหัสผ่านใหม่ ")])])])]],2)]),_c('div',{staticClass:"row justify-content-center mt-5"},[_c('div',{staticClass:"col-4 text-center"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":{ name: 'UserList' }}},[_vm._v("ยกเลิก")])],1),_c('div',{staticClass:"col-4 text-center"},[_c('button',{staticClass:"btn btn-submit",attrs:{"type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" บันทึก ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }